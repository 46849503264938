export const PLACES = [
  [
    {
      title:"WELCOME TO 구름",
      imageSrc: "/assets/culture-place-welcome.png",
      description: "구름에 오신다면 제일 먼저 보게 될 공간! 첫인상이 중요하다는 말처럼, 회사에 방문하는 모든 분들께 짧고 강렬하게 기억에 남을 수 있도록 꾸몄습니다. ",
    },
    {
      title:"LIBRARY",
      imageSrc: "/assets/culture-place-library.png",
      description: "IT 및 디자인, 경영 관련 각종 인기 도서를 구비해둔 공간입니다. 언제든 한 권 골라 걸터 앉아 읽고 싶은 기분이 들도록 디자인했어요. 이 공간에서 지식과 경험, 아이디어를 채워보세요.",
    },
  ],
  [
    {
      title:"CANTEEN",
      imageSrc: "/assets/culture-place-canteen.png",
      description: "생각을 정리하거나 한숨 돌리기에 딱 좋은 공간입니다. 구르미들이 언제나 자유롭게 이용할 수 있도록 음료와 스낵이 항시 구비되어 있어요.",
    },
    {
      title:"OA",
      imageSrc: "/assets/culture-place-oa.png",
      description: "복합기, 파쇄기, 칼이나 가위 같은 사무용 문구를 이용할 수 있는 공간입니다. 기기로부터 발생하는 소음을 최대한 차단하고 업무에 집중할 수 있도록 별도의 OA 공간을 구성했습니다.",
    },
  ],
  [
    {
      title:"TERRACE",
      imageSrc: "/assets/culture-place-terrace.png",
      description: "리프레시가 필요할 때 구르미 전용 테라스에서 볕을 쬐거나 바람을 쐴 수 있어요. 날씨가 좋은 날 파라솔에 앉아 기분 전환해 보세요!",
    },
    {
      title:"MEETING ROOM",
      imageSrc: "/assets/culture-place-meetingroom.png",
      description: "회의 용도에 맞춰 이용할 수 있도록 크고 작은 회의실을 곳곳에 마련해 두었어요. 모든 회의실은 예약을 통해 이용할 수 있으며 회의에 필요한 최신 장비를 갖춰두었습니다. ",
    },
    
  ],
  [
    {
      title:"WORKSPACE",
      imageSrc: "/assets/culture-place-workspace.png",
      description: "협업에 가장 효율적인 자리 배치를 고민했습니다. 쾌적한 사무실 이용을 위해 공기청정기와 공기정화 식물이 곳곳에 배치되어 있어요.",
    },
    {
      title:"RELAX ROOM",
      imageSrc: "/assets/culture-place-relaxroom.png",
      description: "빛과 소음 방해 없이 꿀잠을 잘 수 있는 수면실입니다. 모두가 편하게 이용할 수 있도록 여성용과 남성용 수면실을 별도로 준비했어요. 바쁜 일상 속 제대로 된 휴식을 취해보세요!",
    },
  ]
]