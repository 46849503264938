// extracted by mini-css-extract-plugin
export var ImageDescriptionCard = "ImageDescriptionCard-module--ImageDescriptionCard--8d412";
export var ImageDescriptionCard__active = "ImageDescriptionCard-module--ImageDescriptionCard__active--463cc";
export var ImageDescriptionCard__close = "ImageDescriptionCard-module--ImageDescriptionCard__close--a5787";
export var ImageDescriptionCard__contentActive = "ImageDescriptionCard-module--ImageDescriptionCard__content--active--89390";
export var ImageDescriptionCard__header = "ImageDescriptionCard-module--ImageDescriptionCard__header--3b86c";
export var ImageDescriptionCard__headerActive = "ImageDescriptionCard-module--ImageDescriptionCard__header--active--d7489";
export var ImageDescriptionCard__image = "ImageDescriptionCard-module--ImageDescriptionCard__image--9414a";
export var ImageDescriptionCard__info = "ImageDescriptionCard-module--ImageDescriptionCard__info--8f6bd";
export var ImageDescriptionCard__teamnameActive = "ImageDescriptionCard-module--ImageDescriptionCard__teamname--active--29e85";
export var cardFadeIn = "ImageDescriptionCard-module--cardFadeIn--eedc6";