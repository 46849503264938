import React, { FunctionComponent, useState } from 'react'

import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'

import * as styles from './WorkWaySection.module.scss'
import ImageContentCard from '_components/Culture/ImageContentCard/ImageContentCard';
import CollapseCardList from '_components/Culture/CollapseCardList/CollapseCardList'
import { WORK_WAY_LIST } from '_constants/workWayList'

export type WorkWayDetailContentType = {
  title: string,
  Icon: Node,
  description: string,
}

export type WorkWayItemType = {
  title: string,
  imgSrc: string,
  subTitle: string,
  description: string,
  detailContent?: WorkWayDetailContentType[]
}


const WorkWaySection: FunctionComponent = function () {
  const [selected, setSelected] = useState<string>(Object.keys(WORK_WAY_LIST)[0])
  const selectedItem = WORK_WAY_LIST[selected];
  return (
    <SectionWrapper className={styles.WorkWaySection}>
      <SectionHeader className="flex-column text-center">
        <h1 className="mb-6">
          우리는 이렇게 일해요
        </h1>
      </SectionHeader>
      <article className={styles.WorkWaySection__body}>
          <ImageContentCard
            className={styles.WorkWaySection__body__imageContentCard}
            imgSrc={selectedItem.imgSrc}
            contentList={selectedItem.detailContent}
          />
          <CollapseCardList 
            items = {WORK_WAY_LIST}
            selected = {selected}
            setSelected = {setSelected}
          />
      </article>
    </SectionWrapper>
  )
}

export default WorkWaySection
