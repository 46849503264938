import React, { FunctionComponent, forwardRef } from 'react'
import classNames from 'classnames'

import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import Fade from '_components/Common/Fade/Fade'
import ScrollHint from '_components/Common/ScrollHint/ScrollHint'

import * as styles from './IntroSection.module.scss'
import Responsive from '_components/Common/Responsive/Responsive'

const IntroSection: FunctionComponent = function (_, ref) {
  const MobileBr = <Responsive def={null} sm={<br/>}/>
  return (
    <SectionWrapper
      ref={ref}
      className={classNames(
        styles.IntroSection,
        'd-flex flex-column align-items-center justify-content-center p-0',
      )}
    >
      <Fade>
        <header
          className={classNames(
            styles.IntroSection__header,
            'd-flex justify-content-center',
          )}
        >
          <h1>
            구름답다.
          </h1>
        </header>
      </Fade>
      <Fade delay="1s">
        <article
          className={classNames(
            styles.IntroSection__article,
            'd-flex justify-content-center',
          )}
        >
          <p className="d-md-block text-center">
            구름은 누구나 개발자가 되는 세상을 만들고자 {MobileBr} 누구도 가지 않은 길을 개척합니다.
            <br />
            충분히 해낼 수 있는 일보다는 {MobileBr}조금 더 어려운 문제에 도전하고 있습니다.
          </p>
        </article>
      </Fade>
      <Fade delay="2s">
        <article
          className={classNames(
            styles.IntroSection__article,
            'd-flex justify-content-center',
          )}
        >
          <p className="d-md-block text-center">
            우리가 이토록 도전을 즐기고,
            <br />
            폭발적인 성장을 경험할 수 있었던 이유는 
            <br />
            [구름다움]을 함께 고민하고 만들어 가는{MobileBr} 구르미들이 있기 때문입니다.
          </p>
        </article>
      </Fade>
      <ScrollHint />
    </SectionWrapper>
  )
}

export default forwardRef(IntroSection)
