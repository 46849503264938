import { Card, CardSubtitle, CardText, CardTitle, Fade } from '@goorm-dev/components';
import React, {FunctionComponent, useRef } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap';
import { WorkWayItemType } from '_containers/Culture/WorkWaySection/WorkWaySection';
import ImageContentCard from '../ImageContentCard/ImageContentCard';
import useGridBreakpoint from '_hooks/useGridBreakpoint'

import * as styles from './CollapseCardList.module.scss'

type CollapseCardListProps = {
  items: { [key: string] : WorkWayItemType },
  selected: string,
  setSelected: React.Dispatch<React.SetStateAction<string>>,
  className?: string, 
}

const CollapseCardList: FunctionComponent<CollapseCardListProps> = function ({items, selected, setSelected, className = "" }) {
  const cardRef = useRef(null);
  const currentBreakpoint: string = useGridBreakpoint([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ])

  const onClick = (key) => {
    setSelected(key);
    if(currentBreakpoint !== 'xl' && currentBreakpoint !== 'lg' ) {
      cardRef.current?.scrollIntoView({ behavior: 'smooth'})
    }
  }

  return (
    <ListGroup className={styles.CollapseCardList}> 
      <div ref={cardRef} />
      {Object.keys(items).map((key) => {
        const {title, subTitle, description} = items[key]
        if (selected === key) {
          return (
            <Fade key={key} className={styles.CollapseCardList__cardWrapper}>
              <Card className={styles.CollapseCardList__card}>
                <CardSubtitle className={styles.CollapseCardList__card__subtitle}>{title}</CardSubtitle>
                <CardTitle className={styles.CollapseCardList__card__title}>{subTitle}</CardTitle>
                <CardText>{description}</CardText>
              </Card>
              <ImageContentCard 
                className={styles.CollapseCardList__detailCard}
                imgSrc={items[selected].imgSrc} 
                contentList={items[selected].detailContent}
              />
            </Fade>
          ) 
        }
        return (<ListGroupItem key={key} className={styles.CollapseCardList__list} action onClick={() => onClick(key)}>{title}</ListGroupItem>)
      })}
      
    </ListGroup>
  )
}

export default CollapseCardList