import StudentManagementIcon from '@goorm-dev/components/build/StudentManagementIcon'
import HomeIcon from '@goorm-dev/components/build/HomeIcon'
import MicOnIcon from '@goorm-dev/components/build/MicOnIcon'
import TimeIcon from '@goorm-dev/components/build/TimeIcon'
import BellOffIcon from '@goorm-dev/components/build/BellOffIcon'
import PcIcon from '@goorm-dev/components/build/PcIcon'
import BookIcon from '@goorm-dev/components/build/BookIcon'
import TakeoffIcon from '@goorm-dev/components/build/TakeoffIcon'
import GroupIcon from '@goorm-dev/components/build/GroupIcon'
import CakeIcon from '@goorm-dev/components/build/CakeIcon'
import SavingIcon from '@goorm-dev/components/build/SavingIcon'
import CarIcon from '@goorm-dev/components/build/CarIcon'
import RestaurantIcon from '@goorm-dev/components/build/RestaurantIcon'
import CafeIcon from '@goorm-dev/components/build/CafeIcon'
import GameIcon from '@goorm-dev/components/build/GameIcon'
import BedIcon from '@goorm-dev/components/build/BedIcon'

export const WELFARE_LIST = 
    {
        life: {
            pc: "생활을 지원해요",
            mobile: "생활",
            list: [
            {
                iconComp: CakeIcon,
                title: '생일 축하합니다',
                content: '생일은 소중한 사람들과 행복한 시간을 보낼 수 있도록 생일 당일 1일 휴가를 지급해 드려요. 소정의 상품권도 함께 선물해 드려요.'
            },
            {
                iconComp: StudentManagementIcon,
                title: '졸업 축하합니다',
                content: '구르미의 졸업식에 구름이 빠질 수 없죠! 졸업하는 구르미에게는 특별 휴가와 꽃다발을 드려요.'
            },
            {
                iconComp: HomeIcon,
                title: '전세 자금 대출 이자 지원금',
                content: '직주근접의 꿈! 구름이 지원합니다. 내부 규정에 따라 매월 15-25만원 까지 전세 자금 대출 이자를 지원해 드려요.'
            },
            {
                iconComp: SavingIcon,
                title: '청년내일채움공제',
                content: '구르미와 구름 그리고 대한민국 정부가 2년 동안 합심해서 1,200만원을 적립합니다. (정부 예산 지원 범위 내 신청 가능)'
            },
            {
                iconComp: CarIcon,
                title: 'PDC 건물 주차 지원',
                content: '그 어렵다는 판교 주차! 구르미라면 가능해요. 구름이 둥지를 튼 PDC에는 구르미를 위한 주차 자리가 있어요. (선착순 지원)'
            },
        ]}, 
        growth: {
            pc: '몰입과 성장을 지원해요',
            mobile: '몰입과 성장',
            list:[             
            {
                iconComp: BookIcon,
                title: '구름 도서관',
                content: '구름 도서관을 운영하고 있어요. 업무 시간 중 독서가 가능하며 업무 시간으로 인정해드려요! '
            },
            {
                iconComp: MicOnIcon,
                title: '구름 세미나',
                content: '업계 구루를 초빙하여 사내 세미나를 개최해요. 함께 나누고 싶은 주제가 있다면, 구르미가 직접 진행하기도 해요.  '
            },
            {
                iconComp: TimeIcon,
                title: '자유 출근제',
                content: '08:30~10:30 사이에 자유롭게 출근 시간을 선택할 수 있어요. 복잡한 시간을 피해 여유롭게 출근하세요. 구름은 구르미들의 컨디션을 200% 존중해요! '
            },
            {
                iconComp: BellOffIcon,
                title: 'Deepflow Day',
                content: '매주 금요일은 회의 없는 날이에요. 어떠한 방해도 받지 않고 계획했던 업무에 온전히 집중할 수 있어요. '
            },
            {
                iconComp: PcIcon,
                title: '최신형 디바이스 지원',
                content: '업무에 필요한 디바이스는 고사양, 최신형으로 제공해요. 생산성을 높일 수  있는 수단이라면 새로운 툴 도입도 적극적으로 검토해요. '
            },
        ]},
        rest: {
            pc: '몸과 마음의 휴식을 지원해요',
            mobile: '휴식',
            list: [             
            {
                iconComp: RestaurantIcon,
                title: '점심 지원',
                content: '점심값이나 메뉴 선택 걱정 없이 PDC 건물 구내식당에서 제공되는 매일 색다른 메뉴를 드실 수 있어요. 다이어터를 위한 샐러드도 준비되어 있어요.'
            },
            {
                iconComp: CafeIcon,
                title: '커피와 간식 무제한',
                content: '캔틴에서는 카페 못지않은 커피를 즐길 수 있어요. 일하다 당 떨어지면 큰일 나니까. 취향 저격하는 다양한 과자와 음료도 항시 준비되어 있어요. '
            },
            {
                iconComp: GroupIcon,
                title: '주말 단체 활동비',
                content: '구르미들과 주말에 활동하면 활동비를 지원해 드려요. 취미가 같은 구르미를 찾았다면 함께 활동해 보세요!'
            },
            {
                iconComp: GameIcon,
                title: '게임기, 보드게임, 탁구장',
                content: '스트레스에는 간단한 게임이 최고죠! 언제든지 삼삼오오 모여 즐길 수 있도록 레트로 게임기와 보드게임, 탁구대를 마련해두었어요.'
            },
            {
                iconComp: BedIcon,
                title: '수면실',
                content: '여성과 남성용 수면실이 따로 준비되어 있어요. 빛과 소음에 방해받지 않고 온전히 휴식을 취할 수 있답니다.'
            },
            {
                iconComp: TakeoffIcon,
                title: '자유로운 연차 사용',
                content: '구르미의 건강도 구름의 자산! 휴식은 눈치 보지 말고 쉬고 오세요. 연차 및 반차를 사용할 때 이유에 관해서 묻거나 따지지 않아요. '
            },
        ]},
}
