import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'

import ChevronRightIcon from '@goorm-dev/components/build/ChevronRightIcon';
import ChevronLeftIcon from '@goorm-dev/components/build/ChevronLeftIcon';;
import { Button, ButtonToggleGroup } from '@goorm-dev/components'

import BasicCard from '_components/Culture/BasicCard/BasicCard'
import Responsive from '_components/Common/Responsive/Responsive'
import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'

import { WELFARE_LIST } from '_constants/welfareList'

import * as styles from './WelfareSection.module.scss'



const WelfareSection: FunctionComponent = function () {
  const [selected, setSelected] = useState<string>(Object.keys(WELFARE_LIST)[0]);

  const onClick = (e) => {
    setSelected(e.currentTarget.id)
  }
  
  const selectedIndex = Object.keys(WELFARE_LIST).indexOf(selected)

  const nextIndex = (selectedIndex + 1) % Object.keys(WELFARE_LIST).length;
  const prevIndex = (selectedIndex + Object.keys(WELFARE_LIST).length - 1) % Object.keys(WELFARE_LIST).length;

  return (
    <SectionWrapper className={classNames(styles.WelfareSection, 'bg-gray-50')}>
      <SectionHeader className="flex-column text-center">
        <h1 className="mb-6">
          구르미를 위해 지원해요
        </h1>
      </SectionHeader>
      <article className={styles.WelfareSection__navWrapper}>
        <ButtonToggleGroup className={styles.WelfareSection__nav} size="lg" defaultIndex={selectedIndex}>
          {Object.keys(WELFARE_LIST).map(key => (
            <ButtonToggleGroup.ButtonToggleItem 
              key={key}
              className={styles.WelfareSection__nav__item} 
              onClick={onClick} 
              id={key} 
            > 
                  <Responsive def={WELFARE_LIST[key]?.pc} md={WELFARE_LIST[key]?.mobile}/>
            </ButtonToggleGroup.ButtonToggleItem>
          ))}
        </ButtonToggleGroup>
      </article>
      <article>
        <div className={styles.WelfareSection__cardWrapper}>
            {WELFARE_LIST[selected]?.list.map((item, index) => (
                <BasicCard 
                    key={index}
                    title={item.title}
                    content={item.content}
                    imgUrl={item.iconUrl}
                    Icon={item.iconComp}
                />
            ))}
            {WELFARE_LIST[selected]?.list.length < 6 && 
				        <BasicCard 
                    title="테스트용 타이틀입니다"
                    content="테스트용 텍스트입니다. 다음 복지를 기다려주세요!"
                    imgUrl=""
                    Icon={null}
                    isLock
                />}
        </div>
        <div className={styles.WelfareSection__controller}>
            <div>{selectedIndex+1}/{Object.keys(WELFARE_LIST).length}</div>
            <div className={styles.WelfareSection__controller__btnWrapper}>
              <Button 
                className={styles.WelfareSection__controller__button}
                onClick={()=>setSelected(Object.keys(WELFARE_LIST)[prevIndex])}
              >
                <ChevronLeftIcon />
                {Object.values(WELFARE_LIST)[prevIndex].mobile}
              </Button>
              <Button 
                className={styles.WelfareSection__controller__button} 
                onClick={()=>setSelected(Object.keys(WELFARE_LIST)[nextIndex])}
              >
                {Object.values(WELFARE_LIST)[nextIndex].mobile}
                <ChevronRightIcon />
              </Button>
            </div>
        </div>
      </article>
    </SectionWrapper>
  )
}

export default WelfareSection
