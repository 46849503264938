import React, { useEffect, useState, useRef, FunctionComponent } from 'react'

import Header from '_components/Common/Header/Header'
import PageWrapper from '_components/Common/PageWrapper/PageWrapper'

import RecruitBannerSection from '_containers/Common/RecruitBannerSection/RecruitBannerSection'
import GoormeeSection from '_containers/Culture/GoormeeSection/GoormeeSection'
import ImageSection from '_containers/Culture/ImageSection/ImageSection'
import IntroSection from '_containers/Culture/IntroSection/IntroSection'
import PlaceSection from '_containers/Culture/PlaceSection/PlaceSection'
import WelfareSection from '_containers/Culture/WelfareSection/WelfareSection'
import WorkWaySection from '_containers/Culture/WorkWaySection/WorkWaySection'

const Culture: FunctionComponent = function () {
  const sectionsRef = useRef<HTMLDivElement[]>(Array.from({ length: 5 }))
  const [headerColor, setHeaderColor] = useState<string>('dark')

  useEffect(() => {
    let animationThrottling = false

    const onScroll = () => {
      if (animationThrottling) return
      animationThrottling = true

      const scrollY = window.pageYOffset
      const section1Height = sectionsRef.current[0]?.clientHeight

      requestAnimationFrame(() => {
        animationThrottling = false
      })

      // Section1 에 있음
      const sum = section1Height
      if (scrollY < sum) {
        setHeaderColor('dark')
        return
      }
      setHeaderColor('light')
    }

    document.addEventListener('scroll', onScroll, {
      passive: true,
    })
  }, [])
  return (
      <PageWrapper Header={<Header color={headerColor} />}> 
        <IntroSection ref={ref => {
          sectionsRef.current[0] = ref
        }}/> 
        <GoormeeSection />
        <WorkWaySection />
        <WelfareSection />
        <ImageSection />
        <PlaceSection />
		    <RecruitBannerSection />
      </PageWrapper>
    )
  }

export default Culture
