import React, { FunctionComponent } from 'react'
import {Card, CardText, CardBody, CardTitle} from 'reactstrap';

import { WorkWayDetailContentType } from '_containers/Culture/WorkWaySection/WorkWaySection';
import * as styles from './ImageContentCard.module.scss'
import classNames from 'classnames'

type ImageContentCardProps = {
  imgSrc: string, 
  contentList: WorkWayDetailContentType[],
  className: string,
  ref? : any,
}

const ImageContentCard: FunctionComponent<ImageContentCardProps> = function ({imgSrc, contentList, className, ref}) {
  return (
  <Card className={classNames(styles.ImageContentCard, className)} ref={ref}>
    <div className={styles.ImageContentCard__imgWrapper} style={{backgroundImage: `url(${imgSrc})`}} />
    <div className={styles.ImageContentCard__contentWrapper}>
      {contentList.map(content => {
          const {title, Icon, description} = content
          return (
            <CardBody key={title}>
              <CardTitle className={styles.ImageContentCard__title}><Icon/>{title}</CardTitle>
              <CardText>{description}</CardText>
            </CardBody>
          )
      })}
    </div>
  </Card>
  )
}

export default ImageContentCard