import React, { useState } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import CarouselController from '_components/Common/CarouselController/CarouselController';
import Responsive from '_components/Common/Responsive/Responsive';

import * as styles from './ImageSection.module.scss'

const IMAGE_LIST = [
    '/assets/culture-image0.png',
    '/assets/culture-image1.png',
    '/assets/culture-image2.png',
    '/assets/culture-image3.png',
    '/assets/culture-image4.png',
    '/assets/culture-image5.png',
    '/assets/culture-image6.png',
]

const IMAGE_MOBILE_LIST = [
    '/assets/culture-image-mobile0.png',
    '/assets/culture-image-mobile1.png',
    '/assets/culture-image-mobile2.png',
    '/assets/culture-image-mobile3.png',
    '/assets/culture-image-mobile4.png',
    '/assets/culture-image-mobile5.png',
    '/assets/culture-image-mobile6.png',
]

const ImageSection = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isPaused, setIsPaused] = useState<boolean>(false);

    const slides = IMAGE_LIST.map((image, index) => (
		<CarouselItem
			key={image}
			className={styles.ImageSection__carousel__item}
		>
			<Responsive def={<img src={image}/>} sm={<img src={IMAGE_MOBILE_LIST[index]}/>}/>
		</CarouselItem>
	));

    const next = () => {
        const nextIndex = (activeIndex + 1) % IMAGE_LIST.length; 
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        const prevIndex = (activeIndex + (IMAGE_LIST.length - 1)) % IMAGE_LIST.length;
        setActiveIndex(prevIndex);
    }

    const togglePause = () => {
        setIsPaused(!isPaused);
    }

    return (
        <div className={styles.ImageSection}>
			<Carousel
				className={styles.ImageSection__carousel}
				activeIndex={activeIndex}
				next={next}
				previous={previous}
				interval={3000}
				keyboard={false}
			>
				{slides}
			</Carousel>
            <CarouselController
                className={styles.ImageSection__indicator}
                controlClassName={styles.ImageSection__indicator__control}
                activeIndex={activeIndex}
                count={IMAGE_LIST.length}
                isPaused={isPaused}
                onClickPrevious={previous}
                onClickNext={next}
                onClickPause={togglePause}
            />
		</div>
    )
}

export default ImageSection