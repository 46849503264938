import React, { FunctionComponent } from 'react'
import * as styles from './ImageDescriptionCard.module.scss'
import Fade from '_components/Common/Fade/Fade'
import classNames from 'classnames'
import { Badge } from '@goorm-dev/components'

type ImageDescriptionCardProps = {
  title: string,
  header? : string,
  description: string,
  imageSrc: string,
  className? : string,
}

const ImageDescriptionCard: FunctionComponent<ImageDescriptionCardProps> = function ({
  title,
  header = null,
  description,
  imageSrc,
  className,
}) {
  const [isActive, setIsActive] = React.useState<boolean>(false)
  const cardRef = React.useRef<HTMLInputElement>(null)

  const handleClose = (event: React.MouseEvent<HTMLImageElement>) => {
    if (cardRef.current) {
      event.preventDefault()
      setIsActive(false)
      cardRef.current.blur()
    }
  }

  const Body: React.FC = () => {
    if (isActive) {
      return (
        <>
          <div className={styles.ImageDescriptionCard__info}>
            <img
              src='/assets/close-icon.svg'
              onClick={handleClose}
              className={styles.ImageDescriptionCard__close}
            />
            <Fade distance="20px" durationFunc="ease-out" freezeOnceVisible>
              <div className={styles.ImageDescriptionCard__teamnameActive}>
                {header && <Badge size="md" color="light">
                  {title}
                </Badge>}
              </div>
              <header className={styles.ImageDescriptionCard__headerActive}>
                <h3>{header ? header : title}</h3>
              </header>
              <article className={styles.ImageDescriptionCard__contentActive}>
                {description}
              </article>
            </Fade>
          </div>
        </>
      )
    } else {
      return (
        <>
          <header className={classNames(styles.ImageDescriptionCard__header, 'mb-2')}>
            <h2>{title}</h2>
          </header>
          <div>
            <img src='/assets/chevron-right-icon.svg'/>
          </div>
        </>
      )
    }
  }

  const backgroundActiveOptions = isActive && {
    filter: 'blur(26px) brightness(0.7)',
    transform: 'scale(1.2)',
  }

  return (
    <div
      className={classNames(className, styles.ImageDescriptionCard, isActive ? styles.ImageDescriptionCard__active : null)}
      ref={cardRef}
      onMouseEnter={() => {
        setIsActive(true)
      }}
      onMouseLeave={() => {
        setIsActive(false)
      }}
      onBlur={() => {
        setIsActive(false)
      }}
      onClick={() => {
        if (!isActive) setIsActive(true)
      }}
    >
      <div className={styles.ImageDescriptionCard__image} style={{backgroundImage: `linear-gradient(177.91deg, rgba(0, 0, 0, 0.5) 1.71%, rgba(43, 45, 54, 0) 98.24%), url(${imageSrc})`, ...backgroundActiveOptions}} />
      <Body />
    </div>
  )
}

export default ImageDescriptionCard


