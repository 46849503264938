import classNames from 'classnames'
import React, { FunctionComponent } from 'react'

import ChevronLeftIcon from '@goorm-dev/components/build/ChevronLeftIcon'
import ChevronRightIcon from '@goorm-dev/components/build/ChevronRightIcon'
import PlayIcon from '@goorm-dev/components/build/PlayIcon'
import PauseIcon from '@goorm-dev/components/build/PauseIcon'

import * as styles from './CarouselController.module.scss'

type CarouselControllerProps = {
  onClickPause: any,
  activeIndex: number,
  count: number,
  controlClassName: string,
  onClickPrevious: any,
  isPaused: boolean,
  onClickNext: any,
  className: string,
}

const CarouselController: FunctionComponent<CarouselControllerProps> = function ({
  onClickPause,
  activeIndex,
  count,
  controlClassName,
  className,
  onClickPrevious,
  isPaused,
  onClickNext
}) {
  return (
    <div className={classNames(styles.CarouselController, className)}>
			<span className={styles.CarouselController__page}>
				<span className={styles.CarouselController__page__index}>
					{activeIndex + 1}
				</span>
				/{count}
			</span>
			<div
				className={classNames(
					styles.CarouselController__control,
					controlClassName
				)}
			>
				<ChevronLeftIcon
					className={styles.CarouselController__control__item}
					onClick={onClickPrevious}
				/>
				{isPaused? 
          <PlayIcon 
            className={styles.CarouselController__control__item}
            onClick={onClickPause}
          /> : 
          <PauseIcon 
            className={styles.CarouselController__control__item}
            onClick={onClickPause}
          />
        }
				<ChevronRightIcon 
          className={styles.CarouselController__control__item}
					onClick={onClickNext}
        />
			</div>
		</div>
  )
}

export default CarouselController