import React from 'react'

import CalendarIcon from '@goorm-dev/components/build/CalendarIcon'
import EditIcon from '@goorm-dev/components/build/EditIcon'
import AnalysisIcon from '@goorm-dev/components/build/AnalysisIcon'
import GroupIcon from '@goorm-dev/components/build/GroupIcon'
import FlagIcon from '@goorm-dev/components/build/FlagIcon'
import HistoryIcon from '@goorm-dev/components/build/HistoryIcon'
import LikeThumbIcon from '@goorm-dev/components/build/LikeThumbIcon'
import LikeIcon from '@goorm-dev/components/build/LikeIcon'
import MicOnIcon from '@goorm-dev/components/build/MicOnIcon'
import BookIcon from '@goorm-dev/components/build/BookIcon'
import NoticeBoardIcon from '@goorm-dev/components/build/NoticeBoardIcon'
import NavigationIcon from '@goorm-dev/components/build/NavigationIcon'

export const WORK_WAY_LIST = {
    done: {
      title: "완벽보다 완료",
      imgSrc: "/assets/culture-workway-done.png",
      subTitle: <>DONE IS BETTER <br/>THAN PERFECT!</> ,
      description: "변화하는 세상에 빠르게 대응하기 위해 애자일하게 일하고 있어요. 완벽한 기획안을 만드는 데 걸리는 시간보다 아이디어를 실행해 보는 데  걸리는 시간이 더 짧고 배우는 것이 더 많기 때문이에요. ",
      detailContent: [
        {
          title: "스프린트",
          Icon: CalendarIcon,
          description: `'단거리 달리기'를 뜻하는 스프린트는 구름에서 '일하는 기간'을 의미해요.  3주 단위 스프린트로 업무량과 명확한 업무 마감 기한을 미리 정해요.` 
        },
        {
          title: "Standup and Prosper",
          Icon: EditIcon,
          description: '오늘 무슨 업무를 할지, 언제까지 진행할지는 스스로 결정하고 관리해요. 일을 시작하기 위해 누군가의 허락이 필요하지 않아요. ' 
        }
      ]
    },
    team: {
      title: "스쿼드와 팀",
      imgSrc: "/assets/culture-workway-team.png",
      subTitle: <>하이브리드, 양면 조직으로<br/>구성되어 있어요</>,
      description:  "팀과 스쿼드를 중심으로 다양한 형태의 조직이 서로 엮여 움직이는 하이브리드 조직입니다. 개인은 서로 다른 명확한 목적을 지닌 두 개 이상의 조직에 속하는 양면 조직이기도 해요. 문제에 빠르고 유연하게 대처하고, 개인의 능력은 융통성 있게 발휘할 수 있어요.",
      detailContent: [
        {
          title: "스쿼드",
          Icon: AnalysisIcon,
          description: '고객 중심의 사고를 바탕으로 제품을 개선하고 관리하는 조직이에요. 공통된 미션 아래 목적과 목표를 달성하기 위해 노력해요.'
        },
        {
          title: "팀",
          Icon: GroupIcon,
          description: '동일한 직군이 모인 조직으로 업무 성과 향상과 프로세스 고도화를 고민해요. 구성원 간의 활발한 상호작용을 바탕으로 시너지를 만들어내요.'
        }
      ]
    },
    feedback: {
      title: "회고와 피드백",
      imgSrc: "/assets/culture-workway-feedback.png",
      subTitle: <>회고와 피드백을 통해 <br/>더 나은 방식을 찾아 실행해요</>,
      description:  "우리가 일하는 방식에 대해 더 나은 방식은 없는지 고민하고 개선해야 하는 점을 찾아 실행하는 문화를 만들어가고 있어요. 실수가 발생했을 때도 잘못의 책임을 묻기보다는 시스템적으로 재발을 방지할 방법은 없을지 함께 고민해요. 그래야 실수를 통해 한 단계 더 성장할 수 있기 때문이에요. ",
      detailContent: [
        {
          title: "스프린트 회고",
          Icon: FlagIcon,
          description: '매스프린트의 끝에는 스쿼드 구성원이 모여 회고를 진행해요. 좋았던 점과 아쉬웠던 점을 기반으로 다음 스프린트에 어떤 시도를 할지 고민해요.'
        },
        {
          title: "프로젝트 회고",
          Icon: HistoryIcon,
          description: '하나의 프로젝트가 끝나면 자연스럽게 회고 시간을 가져요. 프로젝트를 진행하면서 얻은 인사이트와 개선점에 대해서는 반드시 기록하고 공유해요.'
        }
      ]
    },
    communication: {
      title: "수평적인 소통",
      imgSrc: "/assets/culture-workway-communication.png",
      subTitle: <>유연하고 수평적인 <br/>소통을 지향해요</>,
      description:  "회사에 도움이 되는 더 나은 것이라면 누구나 의견을 제안할 수 있어요. 우리는 서로의 의견을 경청하고 회사는 구성원이 내린 결정을 믿기 때문이에요. 유연하고 수평적인 소통을 바탕으로 합치된 의사는 큰 추진 동력을 얻어요. 우리가 빠르게 움직이고 성장 할 수 있는 이유예요.",
      detailContent: [
        {
          title: "타운홀 미팅",
          Icon: LikeThumbIcon,
          description: '한 달에 한 번 모든 구성원이 모여 우리의 문화가 개선될 수 있는 방향을 제안하고 논의하는 자리에요. 업무나 회사 운영과 관련된 안건이라면 누구나 자유롭게 의견을 낼 수 있어요.'
        },
        {
          title: "1x1 Meeting",
          Icon: LikeIcon,
          description: '업무 몰입이 어렵거나 OKR 달성을 방해하고 있는 요소가 있다면 언제든지 리더 구르미를 찾아주세요. 좋은 제품을 만들고, 성장하기 위한 생산적인 고민을 함께 나눕니다.'
        }
      ]
    },
    growth: {
      title: "지속가능한 성장",
      imgSrc: "/assets/culture-workway-growth.png",
      subTitle: <>스스로의 성장을<br/>위해서 일해요</>,
      description:  "진정한 성과는 성장을 중시하는 조직문화에서 만들어진다고 믿어요. 구성원은 자신의 성장을 위해 일하고, 구름은 구성원 개개인이 가진 역량을 최대한으로 발휘하고 지속해서 성장할 수 있는 환경을 제공하고 제도로 지원하고 있어요. ",
      detailContent: [
        {
          title: "구름 세미나",
          Icon: MicOnIcon,
          description: '외부 연사를 초청하여 사내 세미나를 개최해요. 세미나 후 연사님과 저녁 식사는 보너스! 나누고 싶은 주제가 있다면 구르미가 직접 진행하기도 해요.'
        },
        {
          title: "Bookoverflow",
          Icon: BookIcon,
          description: '업무 시간 중 독서가 가능하며, 업무 시간으로 인정해 드려요. 업무 관련 도서도 구매해 드리며, 누구든지 구매 희망 도서를 남길 수 있어요.'
        }
      ]
    },  
    share: {
      title: "투명한 공유",
      imgSrc: "/assets/culture-workway-share.png",
      subTitle: <>정보는 투명하게<br/>널리 공유해요</>,
      description:  "서로에 대한 신뢰를 바탕으로 누구든 서로의 일의 과정이나 결과를  확인할 수 있고 자유로운 피드백이 이루어질 수 있는 환경이 구축되어 있어요. 덕분에 모두가 목표와 방향성에 대해 빠르게 얼라인될 수 있어요. ",
      detailContent: [
        {
          title: "Demo Day",
          Icon: NoticeBoardIcon,
          description: '분기별로 열리는 데모데이는 스쿼드별로 제품 방향과 제품 출시 계획을 발표하고 공유하는 자리에요. 고객으로서 제품에 대해 생각해보고 피드백을 주고 받아요.'
        },
        {
          title: "얼라인먼트 데이(SYNC! THINK!)",
          Icon: NavigationIcon,
          description: '분기에 한 번 씩 진행되는 얼라인먼트 데이는 성장을 위한 방향성을 공유하는 자리에요. 모든 구성원이 그루브 라운지에 모여 경청하고 의견을 나눠요.'
        }
      ]
    },
  }