import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import { Card, CardTitle, CardText } from 'reactstrap'
import LockIcon from '@goorm-dev/components/build/LockIcon'

import * as styles from './BasicCard.module.scss'

type BasicCardProps = {
  Icon?: Node,
  imgUrl?: string,
  title: string,
  content: string,
  isLock?: boolean
}

const BasicCard: FunctionComponent<BasicCardProps> = function ({Icon = null, imgUrl = "", title, content, isLock = false}) {
  return (
    <Card className={classNames(styles.BasicCard, isLock ? styles.BasicCard__lock : null)}>
      <>
        <div className={styles.BasicCard__iconWrapper}>{Icon? <Icon/> : <img src={imgUrl} />}</div>
        <CardTitle className={styles.BasicCard__title}>{title}</CardTitle>
        <CardText>{content}</CardText>
        {isLock && <div className={styles.BasicCard__comingSoon}><LockIcon/><div>Coming Soon</div> </div>}
      </>
    </Card>
  );
}

export default BasicCard