import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'

import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import ImageDescriptionCard from '_components/Common/ImageDescriptionCard/ImageDescriptionCard'
import { PLACES } from '_constants/placeList'

import * as styles from './PlaceSection.module.scss'
import Responsive from '_components/Common/Responsive/Responsive'
import { Carousel, CarouselIndicators, CarouselItem } from '@goorm-dev/components'

const PlaceSection: FunctionComponent = function () {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex)
  }

  const next = () => {
    setActiveIndex((activeIndex + 1) % PLACES.length)
  }

  const prev = () => {
    setActiveIndex((activeIndex + PLACES.length - 1) % PLACES.length)
  }



  return (
    <SectionWrapper className={classNames(styles.PlaceSection, 'bg-white')}>
      <SectionHeader className="flex-column text-center">
        <h1 className="mb-6">
          구르미들의 공간을 소개해요
        </h1>
      </SectionHeader>
      <article className={styles.PlaceSection__body}>
        <Responsive
          def={
            <>
            {PLACES.flat().map(place => {
              const {title, imageSrc, description} = place
              return (
                <ImageDescriptionCard
                  key={imageSrc}
                  title={title}
                  imageSrc={imageSrc}
                  description={description}
                />
              )
            })}
            </>
          }
          md={
            <Carousel className={styles.PlaceSection__carousel} activeIndex={activeIndex} next={next} prev={prev}>
              <CarouselIndicators className={styles.PlaceSection__body__indicator} items={PLACES} activeIndex={activeIndex} onClickHandler={goToIndex} />
              {PLACES.map((places, index) => {
                return (
                  <CarouselItem key={index}>
                    {places.map(place => {
                      const {title, imageSrc, description} = place
                      return (
                        <ImageDescriptionCard
                          key={imageSrc}
                          title={title}
                          imageSrc={imageSrc}
                          description={description}
                        />
                      )
                    })}
                  </CarouselItem>
                )
            })}
            </Carousel>
          }
        />
      </article>
    </SectionWrapper>
  )
}

export default PlaceSection
