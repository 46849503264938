import React, { FunctionComponent, useState, useRef } from 'react'
import classNames from 'classnames'
import { throttle } from 'lodash';

import SectionHeader from '_components/Common/SectionHeader/SectionHeader'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import CardScrollBar from '_components/Common/CardScrollBar/CardScrollBar';

import * as styles from './GoormeeSection.module.scss'
import { Badge, Card } from '@goorm-dev/components'

const GOORMEE_IS_LIST = [
  {
    header: "첫번째",
    icon: "/assets/culture-goormee-solve.svg",
    content: <>도전을 즐기고 <br/>문제 해결을 위해 노력해요</>
  },
  {
    header: "두번째",
    icon: "/assets/culture-goormee-friendly.svg",
    content: <>실력에 대한 우월감보다는<br/>친화력으로 협업해요</>,
  },
  {
    header: "세번째",
    icon: "/assets/culture-goormee-mywork.svg",
    content: <>회사 일이 아니라<br/>나의 ‘일’을 만들어나가요</>,
  },
  {
    header: "네번째",
    icon: "/assets/culture-goormee-best.svg",
    content: <>최고가 되기 위해<br/>끊임없이 노력해요</>,
  },
  {
    header: "다섯번째",
    icon: "/assets/culture-goormee-done.svg",
    content: <>DONE IS BETTER THAN<br/>PERFECT을 이해해요</>,
  },
  {
    header: "여섯번째",
    icon: "/assets/culture-goormee-structuring.svg",
    content: <>비구조화된 일을<br/>함께 구조화해나가요</>,
  },
  {
    header: "일곱번째",
    icon: "/assets/culture-goormee-responsibility.svg",
    content: <>높은 자존감을 바탕으로<br/>책임감 있게 임해요</>,
  },
]

const GoormeeSection: FunctionComponent = function ({ref: cardListRef, onScroll: cardScrollHandler}) {
	
  return (
    <SectionWrapper className={classNames(styles.GoormeeSection, 'bg-gray-50')}>
      <SectionHeader className="flex-column text-center">
        <h1>
          구르미 답다는 건
        </h1>
      </SectionHeader>
		<CardScrollBar>
		  <article className={styles.GoormeeSection__body} onScroll={cardScrollHandler} ref={cardListRef}>
			<div className={styles.GoormeeSection__inline}>
			{GOORMEE_IS_LIST.map((item, index)=> {
			  return (
				<Card key={index} className={styles.GoormeeSection__card}>
				  <Badge className={styles.GoormeeSection__badge}>{item.header}</Badge>
				  <img src={item.icon} />
				  {item.content}
				</Card>
			  )
			})}
			</div>
		  </article>
		</CardScrollBar>	  
    </SectionWrapper>
  )
}

export default GoormeeSection
